const useIsCheckoutPage = () => {
  if (typeof window !== 'undefined') {
    return (
      window.location.href.includes('/sign-up') ||
      window.location.href.includes('/pricing') ||
      (window.location.href.includes('/checkout') && !window.location.href.includes('/success'))
    );
  }

  return false;
};

export default useIsCheckoutPage;
