import { FC, ComponentProps, memo } from 'react';
import ExpandIcon from 'components/ui/icons/UI/Accordion/ExpandIcon';
import { StyledAccordionSummary } from './styles';

const AccordionSummary: FC<ComponentProps<typeof StyledAccordionSummary>> = ({
  children,
  expandIcon,
  ...restProps
}) => (
  <StyledAccordionSummary {...restProps} expandIcon={expandIcon || <ExpandIcon />}>
    {children}
  </StyledAccordionSummary>
);

export default memo(AccordionSummary);
