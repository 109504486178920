import { FC, memo } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { StyledButton } from './styles';

const Button: FC<ButtonProps> = ({ children, ...restProps }) => {
  return (
    <StyledButton disableRipple disableElevation disableFocusRipple {...restProps}>
      {children}
    </StyledButton>
  );
};

export default memo(Button);
