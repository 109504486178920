import { FC, memo, useCallback } from 'react';
import { TEMPLATE_LINK } from 'constants/constants';
import { useRouter } from 'next/router';
import { FooterCTAButton, FooterCTATitle, FooterCTAWrapper } from './styled';
import TranslationSpan from 'components/ui/TranslationSpan';

const FooterCTA: FC = () => {
  const router = useRouter();

  const onCTAClick = useCallback(() => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    router.push(TEMPLATE_LINK + window?.location?.search, undefined);
  }, [router]);

  return (
    <FooterCTAWrapper>
      <FooterCTATitle>
        <TranslationSpan phrase="qr.auth.footer.title" />
      </FooterCTATitle>
      <FooterCTAButton onClick={onCTAClick}>
        <TranslationSpan phrase="qr.auth.ctaTitle" />
      </FooterCTAButton>
    </FooterCTAWrapper>
  );
};

export default memo(FooterCTA);
