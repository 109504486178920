import styled from '@emotion/styled';
import AccordionSummaryMUI from '@mui/material/AccordionSummary';
import { breakpoints, mediaMax, COLORS } from 'styles';

export const StyledAccordionSummary = styled(AccordionSummaryMUI)`
  padding: 14px 38px 12px 40px;

  &,
  &.Mui-expanded {
    min-height: unset;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    flex-direction: column;
  }

  .MuiAccordionSummary-expandIconWrapper {
    align-self: flex-start;
    color: ${COLORS.BLUE500};
    width: 16px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 9px;
    }
  }

  &.Mui-disabled {
    opacity: 1;
    color: ${COLORS.INACTIVE_TEXT};

    .MuiAccordionSummary-expandIconWrapper {
      color: ${COLORS.INACTIVE_TEXT};
    }
  }

  ${mediaMax(breakpoints.builder.md)} {
    padding: 15px 15px 12px 15px;
  }
`;
