import { FC, memo } from 'react';
import { LangVariant, TitleContainer } from './styles';
import GlobeIcon from 'components/ui/icons/UI/LangSelect/GlobeIcon';
import { useTranslation } from 'react-i18next';

type LangOptionProps = {
  onClick: () => void;
  label: string;
  locale: string;
};

const LangOption: FC<LangOptionProps> = ({ onClick, label, locale }) => {
  const { i18n } = useTranslation();

  if (locale === i18n.language) return null;
  return (
    <LangVariant onClick={onClick}>
      <TitleContainer>
        <GlobeIcon />
        {label}
      </TitleContainer>
    </LangVariant>
  );
};

export default memo(LangOption);
