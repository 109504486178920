import { memo } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles';

const ErrorMessage = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;

  &:not([data-error='false']) {
    color: ${COLORS.RED500};
  }
`;
ErrorMessage.displayName = 'ErrorMessage';

export default memo(ErrorMessage);
