import { FC, SyntheticEvent, ComponentProps, useState, useCallback, memo, ReactNode } from 'react';
import ErrorMessage from 'components/ui/ErrorMessage';
import { StyledAccordion, Container } from './styles';

const DEFAULT_EXPANDED = true;

export type AccordionProps = ComponentProps<typeof StyledAccordion> & {
  helperText?: ReactNode;
  error?: boolean;
};

const Accordion: FC<AccordionProps> = ({
  children,
  defaultExpanded,
  expanded,
  onChange,
  disabled,
  helperText,
  error,
  basicLayout = false,
  ...restProps
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded ?? defaultExpanded ?? DEFAULT_EXPANDED);

  const expandHandler = useCallback(
    (event: SyntheticEvent, isExpanded) => {
      setIsExpanded(isExpanded);
      onChange?.(event, isExpanded);
    },
    [onChange]
  );

  return (
    <Container>
      <StyledAccordion
        {...restProps}
        defaultExpanded={defaultExpanded ?? DEFAULT_EXPANDED}
        expanded={!disabled && (expanded ?? isExpanded)}
        disabled={disabled}
        onChange={expandHandler}
        data-error={Boolean(error)}
        basicLayout={basicLayout}
      >
        {children}
      </StyledAccordion>
      {helperText ? <ErrorMessage data-error={Boolean(error)}>{helperText}</ErrorMessage> : null}
    </Container>
  );
};

export default memo(Accordion);
