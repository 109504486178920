import { memo } from 'react';
import styled from '@emotion/styled';
import AccordionDetailsMUI from '@mui/material/AccordionDetails';
import { breakpoints, mediaMax } from 'styles';

const AccordionDetails = styled(AccordionDetailsMUI)`
  padding: 0 38px 40px 40px;

  ${mediaMax(breakpoints.builder.md)} {
    padding: 0 15px 15px 15px;
  }
`;

export default memo(AccordionDetails);
