import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  border-radius: 6px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-transform: none;
  word-break: break-all;
  word-break: break-word;
`;
