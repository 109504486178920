import { FC, memo } from 'react';
import MUIHidden, { HiddenProps } from '@mui/material/Hidden';
import { Container } from './styled';

type HiddenPropsType = HiddenProps & {
  noContainer?: boolean;
};

const Hidden: FC<HiddenPropsType> = ({ children, noContainer, ...props }) => (
  <MUIHidden implementation="css" {...props}>
    {props.implementation === 'js' || noContainer ? children : <Container>{children}</Container>}
  </MUIHidden>
);

export default memo(Hidden);
