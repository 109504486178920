import { useTranslation } from 'react-i18next';
import { BASE_MOBI_URL } from '../constants/api';
import { DEFAULT_LOCALE } from 'constants/languages';

export type Domain = 'main' | 'mobi';

export const DOMAIN_URL: Record<Domain, string> = {
  main: process.env.NEXT_PUBLIC_MAIN_DOMAIN as string,
  mobi: BASE_MOBI_URL,
};

/**
 * Returns the URL for a cross-domain route without breaking the translation.
 */
export const useCrossDomainLink = (domain: Domain, route: string) => {
  const {
    i18n: { language },
  } = useTranslation();

  const isDefaultLocale = language === DEFAULT_LOCALE;

  return `${DOMAIN_URL[domain]}${isDefaultLocale ? '' : `/${language}`}${route}`;
};
