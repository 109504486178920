import { useState, useMemo, useEffect } from 'react';
import { breakpoints } from 'styles';

type useCheckMobileScreenValue = {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  height: number;
  width: number;
};

type UseCheckScreenWidth = (options?: {
  mobileWidth?: number;
  tableWidth?: number;
  laptopWidth?: number;
  desktopWidth?: number;
}) => useCheckMobileScreenValue;

/**
 *
 * @description Hook to detect is it mobile screen
 *
 * @type {useCheckMobileScreenValue}
 *
 */

// TODO: change resize and use https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
// TODO: Split into multiple smaller hooks for each breakpoint to prevent unwanted re-renders
export const useCheckMobileScreen: UseCheckScreenWidth = ({
  mobileWidth,
  tableWidth,
  laptopWidth,
  desktopWidth,
} = {}): useCheckMobileScreenValue => {
  const isClient = typeof window === 'object';
  const [width, setWidth] = useState(() => (isClient ? window.innerWidth : 0));
  const [height, setHeight] = useState(() => (isClient ? window.innerHeight : 0));

  const isMobile = useMemo(() => width < Math.max(breakpoints.tabletXS, mobileWidth || 0), [mobileWidth, width]);
  const isTablet = useMemo(() => width < Math.max(breakpoints.tabletM, tableWidth || 0), [tableWidth, width]);
  const isLaptop = useMemo(() => width < Math.max(breakpoints.laptopS, laptopWidth || 0), [laptopWidth, width]);
  const isDesktop = useMemo(() => width >= Math.max(breakpoints.laptopS, desktopWidth || 0), [desktopWidth, width]);

  useEffect(() => {
    if (!isClient) return undefined;

    const handleWindowSizeChange = (): void => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isClient]);

  const value = useMemo(() => {
    return {
      isMobile,
      isTablet,
      isLaptop,
      isDesktop,
      height,
      width,
    };
  }, [height, isDesktop, isLaptop, isMobile, isTablet, width]);

  return value;
};
