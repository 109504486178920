import { DataAttributes, useTranslationEditorContext } from '../providers/TranslationEditor';
import { useTranslation } from 'react-i18next';

export const useTranslationWithAttributes = (translationKey: string): [string, DataAttributes] => {
  const { t } = useTranslation();
  const translatedText = t(translationKey);

  const { getDataAttributes } = useTranslationEditorContext();
  const translationAttributes = getDataAttributes(translationKey);

  return [translatedText, translationAttributes];
};
