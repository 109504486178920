import { FC, memo } from 'react';

const ExpandIcon: FC<{ color?: string }> = ({ color = 'var(--accordion-expand-icon-color, currentColor)' }) => {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.43.55a2.03 2.03 0 0 0-2.77 0L8.5 4.5 4.34.55a2.03 2.03 0 0 0-2.77 0 1.8 1.8 0 0 0 0 2.63l5.54 5.27a2.03 2.03 0 0 0 2.77 0l5.55-5.27a1.8 1.8 0 0 0 0-2.63Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(ExpandIcon);
