import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import { COLORS, breakpoints, mediaMin } from 'styles';

export const StyledAccordion = styled(Accordion)<{ basicLayout?: boolean }>`
  &.Mui-expanded {
    margin: 0;
  }

  &::before {
    display: none;
  }

  &.MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0px 1px 15px rgba(12, 37, 54, 0.15);
    border: 1px solid transparent;
  }

  &.MuiPaper-root[data-error='true'] {
    border: 1px solid ${COLORS.RED500};
  }

  &.Mui-disabled {
    background: #ffffff;
  }

  ${({ basicLayout }) =>
    basicLayout &&
    css`
      &.MuiPaper-root {
        border-radius: 0px;
        box-shadow: none;
        border: none;
      }

      .MuiAccordionDetails-root {
        padding: 0 0 16px 0 !important;

        ${mediaMin(breakpoints.tabletL)} {
          padding-bottom: 40px !important;
        }
      }

      .MuiAccordionSummary-root {
        padding: 0px !important;
      }
    `}
`;

export const Container = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
