/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AccountState } from './index';

export const selectAccount = (state: RootState) => state.account;

export const selectAccountSettings = createSelector([selectAccount], (account: AccountState) => account.account);
export const selectAvailableLanguages = createSelector([selectAccount], (account: AccountState) => account.languages);
// We don't use the code, we just save the country selected in whatever language the user has selected
export const selectCountries = createSelector([selectAccount], (account: AccountState) =>
  account.countries.map((country) => country.name)
);
export const selectAvailableNativeLanguages = createSelector(
  [selectAccount],
  (account: AccountState) => account.nativeLanguages
);
